import { useEffect } from "react";
import { AspectRatio, Button, Divider, Image, Overlay, Stack, Text, UnstyledButton } from "@mantine/core";
import { RedirectMultiUrlProp } from "../../models/RedirectModel";
import { logAnalyticUpdate } from "../../services/firestore-endpoints";
import { useParams } from "react-router-dom";
import { isDesktop } from "react-device-detect";

export default function RedirectMultiUrl({ data, analyticId }: { data: RedirectMultiUrlProp; analyticId: string }) {
  const { code } = useParams();

  const isImageAvailable = data.image && data.image.length > 0;

  useEffect(() => {
    document.title = `${data?.title ?? "URL"}`;
    document.body.style.backgroundColor = "#000";
  });

  return (
    <>
      <AspectRatio style={{ zIndex: -1 }} pos="fixed" top={0} left={0} right={0} bottom={0}>
        <img
          src={
            isImageAvailable
              ? data.image
              : "https://images.saymedia-content.com/.image/c_limit%2Ccs_srgb%2Cq_auto:eco%2Cw_700/MTc2MjkwOTQ4OTk3MTI5Mzg5/yelloworange.webp"
          }
          alt={data.title}
          height={250}
          content="contain"
        />
        <Overlay
          gradient="linear-gradient(0deg, rgba(0, 0, 0, 0.99) 0%, rgba(0, 0, 0, 0) 100%)"
          opacity={1}
          blur={64}
        />
      </AspectRatio>

      <Stack gap="xl" pb="md">
        <Stack mt="lg" gap="xl" align="center">
          {isImageAvailable && <Image src={data.image} radius="lg" h={150} w={150} fit="contain" alt={data.title} />}

          <Stack align="center" gap="xs">
            {/* Title */}
            <Text fw="bold" fz={32} c={isImageAvailable ? "white" : "black"} lineClamp={1} style={{ zIndex: 1000 }}>
              {data.title}
            </Text>
            {/* Description */}
            <Text c={isImageAvailable ? "white" : "black"}>{data.message}</Text>
          </Stack>
        </Stack>

        <Stack px="xs" gap="sm" align="center">
          {data.urls.map((url, index) => {
            return (
              <Button
                key={index}
                w={isDesktop ? "50%" : "100%"}
                size="lg"
                color="gray.7"
                onClick={() =>
                  logAnalyticUpdate(code!, analyticId, url.url, () => {
                    window.location.replace(url.url);
                  })
                }
              >
                {url.name}
              </Button>
            );
          })}

          <Divider mt="5%" w={isDesktop ? "50%" : "100%"} />
          <UnstyledButton
            fz="sm"
            c="dimmed"
            component="a"
            onClick={() => window.open("https://custqrd.web.app", "_self")}
          >
            Powered by CustQRd
          </UnstyledButton>
        </Stack>
      </Stack>
    </>
  );
}
