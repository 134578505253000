import { ActionIcon, CopyButton, Flex, Group, Paper, rem, Skeleton, Stack, Tooltip, Text } from "@mantine/core";
import { IconCheck, IconChevronRight, IconCopy, IconWifi, IconWorld } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { AnalyticProp } from "../models/Analytics";
import { RedirectType } from "../models/RedirectModel";

export function LinkModule(props: {
  id: string;
  type: string;
  analytics?: AnalyticProp[];
  isLoading?: boolean;
  onNavigate?: () => void;
}) {
  const { t } = useTranslation();

  const getIcon = () => {
    switch (props.type) {
      case RedirectType.WIFI:
        return <IconWifi style={{ width: rem(24) }} />;

      default:
        return <IconWorld stroke={1} style={{ width: rem(24) }} />;
    }
  };

  return (
    <Paper
      withBorder
      radius="lg"
      key={props.id}
      shadow="sm"
      style={{ cursor: props.isLoading ? "" : "pointer" }}
      onClick={props?.onNavigate}
    >
      <Stack p="md">
        <Skeleton visible={props.isLoading ?? false}>
          <Flex justify="space-between" align="center" gap="md">
            {/* ICON */}
            {getIcon()}
            {/* <Skeleton visible={props.isLoading ?? false}> */}
            <Group gap={4} justify="center">
              <Text size="lg" c="dimmed">
                {props.analytics?.length ?? 0}
              </Text>

              <IconChevronRight style={{ width: rem(16), paddingBottom: 4 }} />
            </Group>
            {/* </Skeleton> */}
          </Flex>
        </Skeleton>

        <Skeleton visible={props.isLoading ?? false}>
          <Group justify="space-between" align="center">
            <Text size="lg" fw="bold" lineClamp={1}>
              {props.id}
            </Text>

            {/* COPY */}
            <CopyButton value={`https://custqrd.web.app/r/${props.id}`} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? t("general.copied") : t("general.copyLink")} withArrow position="right">
                  <ActionIcon size="md" color={copied ? "teal" : "gray"} variant="subtle" onClick={copy}>
                    {copied ? <IconCheck style={{ width: rem(20) }} /> : <IconCopy style={{ width: rem(20) }} />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Group>
        </Skeleton>
        {/* {analytics && (
        <Text fz="xs" c="dimmed">
          {moment(analytics[0].data().created.toDate())
            .calendar(null, {
              sameDay: "[Today], hh:mm a",
              lastDay: "[Yesterday], hh:mm a",
              lastWeek: "[Last] dddd, hh:mm a",
              sameElse: "DD/MM/YYYY, hh:mm a",
            })
            .toString()}
        </Text>
      )} */}
      </Stack>
    </Paper>
  );
}
