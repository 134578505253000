/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { getMyCodeAnalytics } from "../../../services/firestore-endpoints";
import { User } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  ActionIcon,
  Flex,
  Group,
  Paper,
  rem,
  ScrollArea,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Switch,
  Table,
  Text,
} from "@mantine/core";
import { AreaChart, PieChart } from "@mantine/charts";
import { AnalyticProp } from "../../../models/Analytics";
import { randomColor } from "../../../components/Util";
import moment from "moment";
import { IconChartLine, IconTable } from "@tabler/icons-react";
import { isDesktop } from "react-device-detect";

export default function AccountLinkDashboard(props: { user: User; analytics: AnalyticProp[] }) {
  const { t } = useTranslation();
  const { linkId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [analytics, setAnalytics] = useState<AnalyticProp[]>(props.analytics);

  const [isPieTypePercentage, setIsPieTypePercentage] = useState<boolean>(
    JSON.parse(localStorage.getItem("link_label_type") ?? "true")
  );

  const [dataBrowsers, setDataBrowsers] = useState<{ name: string; value: number; color: string }[]>([]);
  const [dataDevices, setDataDevices] = useState<{ name: string; value: number; color: string }[]>([]);
  const [dataOsName, setDataOsName] = useState<{ name: string; value: number; color: string }[]>([]);
  const [dataOsVersion, setDataOsVersion] = useState<{ name: string; value: number; color: string }[]>([]);
  const [dataTimeline, setDataTimeline] = useState<any[]>([]);
  const [visitType, setVisitType] = useState<string>("table");

  useEffect(() => {
    document.title = `${t("account.link.title.edit")} - ${t("title")}`;

    if (linkId) {
      // no link-through
      if (analytics?.length > 0) {
        setIsLoading(false);
      } else {
        // analytics not cached
        setIsLoading(true);
        getMyCodeAnalytics(linkId).then((ana) => {
          setAnalytics(ana);
          setIsLoading(false);
        });
      }
    } else {
      // Get latest code
      navigate(`/account`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [isPreLinkEnabled, setIsPreLinkEnabled] = useState<boolean>(linkData?.prelink !== null);

  useEffect(() => {
    if (analytics?.length > 0) {
      // analytics available
      setDataDevices([
        {
          name: t("account.link.device.desktop"),
          value: analytics.map((a) => a.is_desktop).filter((f) => f).length,
          color: randomColor(),
        },
        {
          name: t("account.link.device.mobile"),
          value: analytics.map((a) => a.is_mobile).filter((f) => f).length,
          color: randomColor(),
        },
        {
          name: t("account.link.device.tablet"),
          value: analytics.map((a) => a.is_tablet).filter((f) => f).length,
          color: randomColor(),
        },
      ]);
      setDataOsName(
        analytics
          .map((a) => a.os_name)
          // remove duplicates
          .filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          .map((n) => {
            return {
              name: n,
              value: analytics.map((a) => a.os_name).filter((f) => f === n).length,
              color: randomColor(),
            };
          })
      );
      setDataOsVersion(
        analytics
          .map((a) => a.os_version)
          // remove duplicates
          .filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          .map((n) => {
            return {
              name: n,
              value: analytics.map((a) => a.os_version).filter((f) => f === n).length,
              color: randomColor(),
            };
          })
      );
      setDataTimeline(
        analytics
          .map((a) => moment(a.created.toDate()).format("DD MMM yyyy"))
          .filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          .map((date) => {
            return {
              date: date,
              Visits: analytics.map((a) => moment(a.created.toDate()).format("DD MMM yyyy")).filter((d) => d === date)
                .length,
            };
          })
      );
      setDataBrowsers(
        analytics
          .map((a) => a.browser)
          // remove duplicates
          .filter(function (elem, index, self) {
            return index === self.indexOf(elem);
          })
          .map((n) => {
            return {
              name: n,
              value: analytics.map((a) => a.browser).filter((f) => f === n).length,
              color: randomColor(),
            };
          })
      );
    } else {
      // no analytics
    }
  }, [analytics, t]);

  const iconProps = {
    style: { width: rem(20), height: rem(20), display: "block" },
    stroke: 1.5,
  };

  const visitDataRow = analytics
    ?.map((a) => (
      <Table.Tr key={a.created.toDate().toString()}>
        <Table.Td align="right">
          {moment(a.created.toDate()).calendar(null, {
            sameDay: `[${t("date.format.today")}], HH:mm`,
            lastDay: `[${t("date.format.yesterday")}], HH:mm`,
            lastWeek: `[${t("date.format.last")}] dddd, HH:mm`,
            sameElse: "DD/MM/YYYY, HH:mm",
          })}
        </Table.Td>
        <Table.Td>
          {a.is_desktop
            ? t("account.link.device.desktop")
            : a.is_mobile
            ? t("account.link.device.mobile")
            : a.is_tablet
            ? t("account.link.device.tablet")
            : t("account.link.device.other")}
        </Table.Td>
        <Table.Td>{`${a.os_name} ${a.os_version}`}</Table.Td>
        <Table.Td>{`${a.browser} ${a.browser_version ?? ""}`}</Table.Td>
        {isDesktop && a.redirected_to && (
          <Table.Td>
            <a href={a.redirected_to}>{a.redirected_to?.split("https://")[1]}</a>
          </Table.Td>
        )}
        {isDesktop && (analytics?.filter((f) => f.from_url !== undefined && f.from_url !== "")?.length ?? 0) > 0 && (
          <Table.Td>{a.from_url}</Table.Td>
        )}
      </Table.Tr>
    ))
    .reverse();

  return (
    <>
      <Stack>
        <Paper shadow="md" withBorder p="md">
          <Group>
            <Switch
              label="Percentage"
              checked={isPieTypePercentage}
              onChange={(e) => {
                localStorage.setItem("link_label_type", `${e.currentTarget.checked}`);
                setIsPieTypePercentage(!isPieTypePercentage);
              }}
            />
          </Group>
        </Paper>

        <SimpleGrid cols={{ base: 1, xs: 2, md: 3 }}>
          {/* DEVICES */}
          <Paper shadow="md" withBorder p="md">
            <Stack gap={0}>
              <Text fz="h3" fw="bold">
                {t("account.link.devices")}
              </Text>
              <PieChart
                withLabelsLine
                labelsPosition="outside"
                labelsType={isPieTypePercentage ? "percent" : "value"}
                withLabels
                data={dataDevices}
                mx="auto"
                my={-16}
                withTooltip
                tooltipDataSource="segment"
                size={100}
              />
              <Paper withBorder>
                <Flex gap="xs" align="center" justify="center">
                  {dataDevices.map((d) => {
                    return (
                      <Flex key={d.name} gap={4} align="center" justify="center">
                        <ActionIcon variant="filled" radius="xl" size="xs" color={d.color} />
                        <Text size="sm" mt={4}>
                          {d.name}
                        </Text>
                      </Flex>
                    );
                  })}
                </Flex>
              </Paper>
            </Stack>
          </Paper>
          {/* OS NAME */}
          <Paper shadow="md" withBorder p="md">
            <Stack gap={0}>
              <Text fz="h3" fw="bold">
                {t("account.link.osName")}
              </Text>
              <PieChart
                withLabelsLine
                labelsPosition="outside"
                labelsType={isPieTypePercentage ? "percent" : "value"}
                withLabels
                data={dataOsName}
                mx="auto"
                my={-16}
                withTooltip
                tooltipDataSource="segment"
                size={100}
              />
              <Paper withBorder>
                <Flex gap="xs" align="center" justify="center">
                  {dataOsName.map((d) => {
                    return (
                      <Flex key={d.name} gap={4} align="center" justify="center">
                        <ActionIcon variant="filled" radius="xl" size="xs" color={d.color} />
                        <Text size="sm" mt={4}>
                          {d.name}
                        </Text>
                      </Flex>
                    );
                  })}
                </Flex>
              </Paper>
            </Stack>
          </Paper>
          {/* BROWSERS */}
          <Paper shadow="md" withBorder p="md">
            <Stack gap={0}>
              <Text fz="h3" fw="bold">
                {t("account.link.browsers")}
              </Text>
              <PieChart
                withLabelsLine
                labelsPosition="outside"
                labelsType={isPieTypePercentage ? "percent" : "value"}
                withLabels
                data={dataBrowsers}
                mx="auto"
                my={-16}
                withTooltip
                tooltipDataSource="segment"
                size={100}
              />
              <Paper withBorder>
                <Flex gap="xs" align="center" justify="center">
                  {dataBrowsers.map((d) => {
                    return (
                      <Flex key={d.name} gap={4} align="center" justify="center">
                        <ActionIcon variant="filled" radius="xl" size="xs" color={d.color} />
                        <Text size="sm" mt={4}>
                          {d.name}
                        </Text>
                      </Flex>
                    );
                  })}
                </Flex>
              </Paper>
            </Stack>
          </Paper>
          {/* <Paper shadow="lg" withBorder p="md">
            <Stack gap={0}>
            <Text fz="h2" fw="bold">
                {t("account.link.osName")}
              </Text>
              <PieChart
                withLabelsLine
                labelsPosition="outside"
                labelsType={isPieTypePercentage ? "percent" : "value"}
                withLabels
                data={dataOsVersion}
                mx="auto"
                my={-24}
                size={100}
              />
              <Paper withBorder>
                <Flex gap="xs" align="center" justify="center">
                  {dataOsVersion.map((d) => {
                    return (
                      <Flex gap={4} align="center" justify="center">
                        <ActionIcon variant="filled" radius="xl" size="xs" color={d.color} />
                        <Text mt={4}>{d.name}</Text>
                      </Flex>
                    );
                  })}
                </Flex>
              </Paper>
            </Stack>
          </Paper> */}
        </SimpleGrid>

        <Paper shadow="md" withBorder p="md" h={450}>
          <Stack>
            <Flex justify="space-between" align="center">
              <Text fz="h2" fw="bold">
                {t("account.link.visits")}
              </Text>
              <SegmentedControl
                data={[
                  {
                    value: "table",
                    label: <IconTable {...iconProps} />,
                  },
                  {
                    value: "chart",
                    label: <IconChartLine {...iconProps} />,
                  },
                ]}
                value={visitType}
                onChange={(e) => setVisitType(e)}
              />
            </Flex>
            {visitType === "chart" ? (
              <AreaChart
                h={300}
                data={dataTimeline}
                dataKey="date"
                series={[{ name: "Visits", color: "teal.6" }]}
                curveType="bump"
                tickLine="none"
                gridAxis="y"
                pr="md"
              />
            ) : (
              <ScrollArea h={360} offsetScrollbars type="always">
                <Table stickyHeader striped withColumnBorders>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th align="right">{t("account.link.table.date")}</Table.Th>
                      <Table.Th>{t("account.link.table.device")}</Table.Th>
                      <Table.Th>{t("account.link.table.osDetails")}</Table.Th>
                      <Table.Th>{t("account.link.table.browser")}</Table.Th>
                      {isDesktop && <Table.Th>{t("account.link.table.redirectedTo")}</Table.Th>}
                      {isDesktop &&
                        (analytics?.filter((f) => f.from_url !== undefined && f.from_url !== "").length ?? 0) > 0 && (
                          <Table.Th>{t("account.link.table.fromUrl")}</Table.Th>
                        )}
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>{visitDataRow}</Table.Tbody>
                </Table>
              </ScrollArea>
            )}
          </Stack>
        </Paper>
      </Stack>
    </>
  );
}
