import { Timestamp } from "firebase/firestore";
import { AnalyticProp } from "./Analytics";

export interface AnanlyticLinkProp {
  id: string;
  data: AnalyticProp[];
}

export enum RedirectType {
  URL = "url",
  MULTI_URL = "multi_url",
  WIFI = "wifi",
  VCARD = "vcard",
}

export enum UserAccessType {
  ADMIN = "admin",
  READ_WRITE = "read-write",
  READ = "read",
}

export interface RedirectProp {
  type: RedirectType;
  updated: Timestamp;
  created: Timestamp;
  url: string;
  urls?: { name: string; url: string }[];
  auto_close?: boolean;
  launch_new_tab?: boolean;
  created_by: string;
  notes?: string;
  analytics_enabled?: boolean;
  prelink?: RedirectPrelinkProp;
  wifi?: RedirectWiFiProp;
}

export interface RedirectMultiUrlProp {
  type: RedirectType;
  updated: Timestamp;
  created: Timestamp;
  created_by: string;
  urls: { name: string; url: string }[];
  image?: string;
  title?: string;
  message?: string;
  notes?: string;
  analytics_enabled?: boolean;
  prelink?: RedirectPrelinkProp;
}

export interface RedirectPrelinkProp {
  active_until: Timestamp;
  auto_close?: boolean;
  launch_new_tab?: boolean;
  url: string;
}

export interface RedirectWiFiProp {
  ssid: string;
  type: string;
  password: string;
}
