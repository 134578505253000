import { useEffect, useState } from "react";
import { VCardProp } from "../../models/VCardModel";
import {
  BackgroundImage,
  Button,
  Container,
  Flex,
  Overlay,
  Paper,
  Spoiler,
  Stack,
  Text,
  Title,
  UnstyledButton,
} from "@mantine/core";
import moment from "moment";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { VCardButton } from "../../components/VCardButton";
import { generateVCard } from "../../components/CreateVCard";
import { t } from "i18next";

export default function RedirectVCard(props: { data: VCardProp }) {
  const [contactTagline, setContactTagline] = useState<string[]>([]);

  const theme = props.data.theme;

  useEffect(() => {
    document.title = `${props.data?.name?.first} ${props.data?.name?.last} VCard`;
  });

  useEffect(() => {
    const nickname = props.data.name?.nickname;
    const company = props.data.company?.name;

    const taglineArray = [];
    if (nickname) {
      taglineArray.push(nickname);
    }
    if (company) {
      taglineArray.push(company);
    }
    setContactTagline(taglineArray);
  }, [props]);

  const saveVCard = () => {
    generateVCard(props.data);
  };

  return (
    <Container px={0} size="xs">
      <Stack gap="sm" pb="md">
        <Flex justify="end" pl="sm" pt="sm" pr="sm" c={theme.tint} style={{ zIndex: 1000 }}>
          <Button size="md" onClick={saveVCard}>
            {t("general.save")}
          </Button>
        </Flex>

        <BackgroundImage src={props.data.image ?? ""} h={300} mt={-16} pos="relative">
          <Stack gap={0} align="center" justify="flex-end" h={280}>
            <Overlay gradient="linear-gradient(0deg, rgba(0, 0, 0, 0.99) 0%, rgba(0, 0, 0, 0) 100%)" opacity={0.85} />
            {contactTagline.length > 0 && (
              <Text c="white" style={{ zIndex: 1000 }}>
                {contactTagline.join(" • ")}
              </Text>
            )}
            <Title ff="heading" fz={40} c="white" lineClamp={1} style={{ zIndex: 1000 }}>
              {props.data.name?.prefix} {props.data.name?.first} {props.data.name?.last} {props.data.name?.suffix}
            </Title>
          </Stack>
        </BackgroundImage>

        <Stack px="sm" gap="sm">
          {/* PHONES */}
          {props.data.phones?.map((p) => {
            return <VCardButton key={p.value} theme={theme} field={p} isPhone />;
          })}

          {/* EMAILS */}
          {props.data.emails?.map((p) => {
            return <VCardButton key={p.value} theme={theme} email={p} />;
          })}

          {/* ADDRESSES */}
          {props.data.addresses?.map((a) => {
            return (
              <UnstyledButton
                key={a.street}
                onClick={() => {
                  if (a.coordinates) {
                    window.location.href = `https://www.google.com/maps?saddr=My+Location&daddr=${a.coordinates?.lat},${a.coordinates?.lng}`;
                  }
                }}
              >
                <Paper withBorder p="md" radius={16}>
                  <Flex justify="space-between">
                    <Stack gap={0} align="start">
                      <Text fz="sm">{a.name ?? a.type}</Text>
                      <Text>{a.street}</Text>
                      <Text>{a.city}</Text>
                      <Text>{a.region}</Text>
                      <Text>{a.zip}</Text>
                      <Text>{a.country}</Text>
                    </Stack>
                    <Paper w={100} h={100}>
                      <div style={{ height: "100%", width: "100%" }}>
                        <APIProvider apiKey={"AIzaSyB02WReBuW5aVkHVU7AxOTQEtIm3h-LaFY"}>
                          <Map
                            center={
                              {
                                center: {
                                  lat: a.coordinates?.lat ?? 0,
                                  lng: a.coordinates?.lng ?? 0,
                                },
                              }.center
                            }
                            fullscreenControl={false}
                            streetViewControl={false}
                            defaultZoom={13}
                            tiltInteractionEnabled={false}
                            headingInteractionEnabled={false}
                            scrollwheel={false}
                            scaleControl={false}
                          >
                            <Marker
                              position={
                                {
                                  center: {
                                    lat: a.coordinates?.lat ?? 0,
                                    lng: a.coordinates?.lng ?? 0,
                                  },
                                }.center
                              }
                            />
                          </Map>
                        </APIProvider>
                      </div>
                    </Paper>
                  </Flex>
                </Paper>
              </UnstyledButton>
            );
          })}

          {/* URLS */}
          {props.data.urls?.map((p) => {
            return <VCardButton key={p.value} theme={theme} field={p} />;
          })}

          {/* SOCIALS */}
          {props.data.socials?.map((s) => {
            return <VCardButton key={s.value} theme={theme} field={s} />;
          })}

          {/* BIRTHDAY */}
          {props.data.birthday && (
            <Button
              variant="outline"
              size="lg"
              justify="start"
              fullWidth
              p="xl"
              radius={16}
              leftSection={
                <Stack gap={0} align="start">
                  <Text fz="sm">Opened</Text>
                  <Text c="blue">{moment(props.data.birthday.toDate()).format("dddd, Do MMMM YYYY")}</Text>
                </Stack>
              }
            />
          )}

          {props.data.note && (
            <Spoiler maxHeight={150} showLabel="Show more" hideLabel="Hide">
              <Text>{props.data.note}</Text>
            </Spoiler>
          )}
        </Stack>
      </Stack>
    </Container>
  );
}
